import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";
import {GetProductsResponse} from "@/app/ecommerce/services/product-service/interfaces/get-products-response";
import {ProductModel} from "@/app/ecommerce/services/product-service/interfaces/product.model";
import {CreateProductRequestDto} from "@/app/ecommerce/services/product-service/interfaces/create-product-request.dto";
import {UpdateProductRequestDto} from "@/app/ecommerce/services/product-service/interfaces/update-product-request.dto";

const URL = `${environment.ecommerceApiBaseUrl}products`;
export const ProductService = {
  async getAll(query: string): Promise<GetProductsResponse> {
    const url = URL + query;
    return (await httpClient.get(url)).data.data;
  },
  async store(product: ProductModel): Promise<ProductModel> {
    const createProductRequestDto = this.toCreateRequestDto(product);
    return (await httpClient.post(URL, createProductRequestDto)).data.data;
  },
  async update(product: ProductModel): Promise<ProductModel> {
    const updateProductRequestDto = this.toUpdateRequestDto(product);
    return (await httpClient.post(`${URL}/${updateProductRequestDto.id}?_method=PUT`, updateProductRequestDto)).data.data;
  },
  async delete(ids: string): Promise<void> {
    return httpClient.delete(`${URL}/${ids}`);
  },
  toCreateRequestDto(productModel: ProductModel): CreateProductRequestDto {
    return {
      name: productModel.name,
      category_id: productModel.category.id!
    };
  },
  toUpdateRequestDto(productModel: ProductModel): UpdateProductRequestDto {
    return {
      id: productModel.id!,
      name: productModel.name,
      category_id: productModel.category.id!
    };
  }
};
