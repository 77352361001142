



















































import AgTable from '@/app/shared/shared-components/ag-grid/AgTableServerSide.vue';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell.vue";
import StorePopup from './StorePopup.vue';
import AgButton from "@/app/shared/shared-components/ag-grid/AgButton.vue";
import ImportExcel from "../../../../components/excel/ImportExcel.vue";
import Vue from "vue";
import {ProductModel} from "@/app/ecommerce/services/product-service/interfaces/product.model";
import {CategoryModel} from "@/app/ecommerce/services/category-service/interfaces/category.model";
import utilities from "@/app/shared/utilities";
import {ColDef, GridApi, GridOptions} from "ag-grid-community";
import {ProductService} from "@/app/ecommerce/services/product-service/product.service";
import {GetProductsResponse} from "@/app/ecommerce/services/product-service/interfaces/get-products-response";
import {isArray} from "lodash";
import {SubProductService} from "@/app/ecommerce/services/sub-product-service/sub-product.service";

export default Vue.extend({
  name: "Product",
  data() {
    return {
      gridApi: {} as GridApi | null | undefined,
      isLoading: true,
      gridOptions: {} as GridOptions,
      product: {
        id: undefined,
        name: '',
        category: {
          id: undefined,
          name: ''
        } as CategoryModel
      } as ProductModel,
      permissions: {
        add: 'store_products',
        delete: 'destroy_products',
        edit: 'update_products'
      },
      popupActive: false
    };
  },
  methods: {
    createRecord() {
      utilities.initObjectMembers(this.product);
      (this.$refs?.storePopupRef as any).open();
    },
    editRecord(product: ProductModel) {
      this.product = product;
      (this.$refs?.storePopupRef as any).open();
    },
    async deleteProduct(ids: number | number[]) {
      this.isLoading = true;
      let idsToDelete = ids.toString();
      if (isArray(ids))
        idsToDelete = ids.join(',');

      await ProductService.delete(idsToDelete);
      this.refreshStore();
    },
    refreshStore() {
      this.gridApi?.refreshServerSideStore({});
    },
    async getAll(query: string): Promise<GetProductsResponse> {
      return ProductService.getAll(query);
    },
    createColDefs(): Array<ColDef> {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الصنف',
          field: 'category.name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'المحل',
          field: 'category.store.name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'المنتجات الفرعية',
          cellRendererFramework: AgButton,
          field: 'id',
          width: 275,
          sortable: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'مشاهدة المنتجات الفرعية',
            type: 'gradient',
            permission: 'get_subProducts',
            click(id: number) {
              self.rowClicked(id);
            }
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          sortable: false,
          cellRendererParams: {
            deleteRecord: function (id: number, rowIndex: number) {
              self.deleteProduct(id);
            },
            editRecord: function (product: ProductModel) {
              self.editRecord(product);
            },
            actions: ['edit', 'delete'],
            permissions: self.permissions
          },
        },
      ];
    },
    rowClicked(id: number) {
      this.$router.push(`/ecommerce/products/${id}/sub_products`);
    },
    async importExcelFile(excelData: any) {
      await SubProductService.updateSubProductsFromExcel(excelData.rawFile);
      this.isLoading = false;
      await ProductService.getAll('');
    },
    async exportExcelFile() {
      await SubProductService.exportSubProductsFromExcel();
      this.isLoading = false;
    }
  },
  components: {
    AgTable,
    StorePopup,
    ImportExcel
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
});
