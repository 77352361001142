import {environment} from "@/environment";
import {httpClient} from "@/app/shared/shared-services/httpClient";

export const SubProductService = {
  async updateSubProductsFromExcel(excelData: any) {
    const url = environment.ecommerceApiBaseUrl + `update-excel/subProducts`;
    let excelFile = new FormData();
    excelFile.append('subproducts_excel', excelData);
    await httpClient.post(url, excelFile);
  },
  async exportSubProductsFromExcel() {
    const url = `${environment.ecommerceApiBaseUrl}export-excel/subProducts`;
    const response = await httpClient.get(url, {responseType: 'arraybuffer'});
    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'subProducts.xlsx');
    document.body.appendChild(fileLink);
    fileLink.click();
  },
};
